import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import cn from 'classnames'
import { motion, Variants } from 'framer-motion'
import { CaretDown, LockSimple } from '@phosphor-icons/react'
import * as React from 'react'
import { Button, ButtonProps } from '../../..'
import type { PropsWithoutChildren } from '../../../types/helpers'
import styles from './Dropdown.module.scss'
import { DropdownContext } from './DropdownContext'

export type TriggerProps = {
  /**
   * Required children prop for the menu item, this can just be text like 'Dashboard'
   */
  children: React.ReactNode
  /**
   * Whether to show the rotating caret / lock icon (when disabled)
   */
  showIcons?: boolean
  hideCarat?: boolean
  variant?: 'styled' | 'unstyled'
  variantStyle?: ButtonProps<'button'>['variant']
} & PropsWithoutChildren<Omit<ButtonProps<'button'>, 'variant'>>

const variants: Variants = {
  open: { rotate: 180 },
  closed: { rotate: 0 },
}

export const Trigger: React.VFC<TriggerProps> = (props) => {
  const {
    children,
    className,
    showIcons = true,
    hideCarat = false,
    variant = 'unstyled',
    variantStyle,
    size = 'default',
    disabled,
    ...restProps
  } = props

  const { open } = React.useContext(DropdownContext)

  const buttonContent = (
    <>
      {children}
      {showIcons && !disabled && (
        <motion.div
          className={styles.caret}
          animate={open ? 'open' : 'closed'}
          variants={variants}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          aria-hidden
        >
          {!hideCarat && (
            <CaretDown weight="bold" className="fill-inherit opacity-75" />
          )}
        </motion.div>
      )}
      {showIcons && disabled && (
        <LockSimple weight="bold" className={styles.lockIcon} />
      )}
    </>
  )

  const sharedButtonProps = {
    type: 'button',
    disabled,
    className: cn(styles.trigger, className),
    variant: variantStyle,
    ...restProps,
  } as const

  return (
    <div className={cn(disabled && 'cursor-not-allowed inline-block')}>
      <DropdownMenu.Trigger disabled={disabled} asChild>
        {variant === 'unstyled' ? (
          <button {...sharedButtonProps}>{buttonContent}</button>
        ) : (
          <Button colourVariant="paper" size={size} {...sharedButtonProps}>
            {buttonContent}
          </Button>
        )}
      </DropdownMenu.Trigger>
    </div>
  )
}
